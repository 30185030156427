import React, {useState} from 'react'
//import '../../css/style.css'
import cow1 from '../../img/montbelliard1.jpg';
import cow2 from '../../img/montbelliard2.jpg';
import cow3 from '../../img/montbelliard4.jpg';
import cow4 from '../../img/austin1.jpg';
import cow5 from '../../img/austin2.jpg';
import cow6 from '../../img/austin4.jpg';
import SimpleImageSlider from "react-simple-image-slider";
var Carousel = require('react-responsive-carousel').Carousel;
//import Carousel from "react-responsive-carousel";

const Cows = () => {
  const ImageSliderStyle = { width: '100%', height: 300}
  const images = [
    { url: cow1 },
    { url: cow2 },
    { url: cow3 },
    { url: cow4 },
    { url: cow5 },
    { url: cow6 }
  ];

  return (
    <section id="cows" className="section bg-light">
    <div className="container">  
      <div className="gift-cards">
        <div style={{}}>
          {/* <img src="../img/orangeTree.jpg" alt=""> */}
          {/*<img src= { require ('../../img/montbelliard1.jpg') } /> */}
          <SimpleImageSlider style={{position: 'relative', top: '0', right: '0'}}
            width={ImageSliderStyle.width}
            height={ImageSliderStyle.height}
            images={images}
            showBullets={true}
            showNavs={true}
            autoPlay={true}
            navSize={20}
            showBullets={false}
            autoPlayDelay={6.0}
          />
        </div>
        <div>
          <h2>Variété Animals: </h2>
          <h2>
          <p className='col-2'>
          metisse austin, metisse montbelliard, veaux, 
          genisse, taurions, vaches.</p> 
          <p>Le troupeau entier est a vendre pour 125,000,000 de Franc CFA au Mali.
          </p></h2>
          <a href="#contact" className="text-secondary">
            <i className="fas fa-chevron-right">
              <button style={{"height":"25px", "width":"200px" }} >contactez nous</button></i>
          </a>
          <hr/>
        </div>
      </div>
    </div>
    </section>

  )
}

export default Cows
