import React from 'react'
//import '../../css/style.css'
import orange from '../../img/orangeTree.jpg';
import orange1 from '../../img/mandarin1.jpg';
import orange2 from '../../img/mandarin2.jpg';
import orange3 from '../../img/mandarin3.jpg';
import orange4 from '../../img/clementine1.jpg';
import orange5 from '../../img/clementine2.jpg';
import orange6 from '../../img/fremont1.jpg';
import orange7 from '../../img/fremont2.jpg';
import orange8 from '../../img/tangor1.jpg';
import orange9 from '../../img/tangor2.jpg';
import orange10 from '../../img/tangelo.jpg';
import orange11 from '../../img/tangelo2.jpg';
import SimpleImageSlider from 'react-simple-image-slider';

const Oranges = () => {
  const ImageSliderStyle = { width: '100%', height: 300}
  const images = [
    { url: orange },
    { url: orange1 },
    { url: orange2 },
    { url: orange3 },
    //{ url: orange4 },
    { url: orange5 },
    //{ url: orange6 },
    //{ url: orange7 },
    { url: orange8 },
    //{ url: orange9 },
    //{ url: orange10 },
    //{ url: orange11 }
  ];
  return (
    <section id="oranges" className="section bg-light">
    <div className="container">  
    <div className="gift-cards">
        <div>
          {/* <img src="../img/orangeTree.jpg" alt=""> */}
          {/* <img src= { require ('../../img/orangeTree.jpg') } /> */}
          <SimpleImageSlider style={{position: 'relative', top: '0', right: '0'}}
            width={ImageSliderStyle.width}
            height={ImageSliderStyle.height}
            images={images}
            showBullets={true}
            showNavs={true}
            autoPlay={true}
            navSize={20}
            showBullets={false}
            autoPlayDelay={5.0}
          />
        </div>
        <div>
          <h2>Variété Oranges:</h2>
          <p className='col-2'>
          <h2>tangelo, clemantine, fremont, tangor, mandarins, ocievala, fershell</h2>
          </p>
          <h2>
          <p>Les prix varient selon les varietes d'oranges.</p>
          <p>Le kilogram coute entre 400 CFA - 500 CFA.</p>
          </h2>

          <p>
            <form method="GET"  action="https://c1infa9fgf.execute-api.us-east-1.amazonaws.com/myLambdaPrebuildStripeCheckout" >
              <button style={{"height":"25px", "width":"200px" }} name="buy_orange" type="submit">Achetez des Orange</button>
            </form>
          </p>
          <hr/>
          <a href="#" className="text-secondary">
            <i className="fas fa-chevron-right"></i>
          </a>
        </div>
    </div>
    </div>
    </section>
  )
}

export default Oranges
