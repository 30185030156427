import { findAllByTitle } from '@testing-library/dom'
import { Link } from 'react-router-dom'

const Cancel = () => {
  return (
    <div>
      <Link  class='cf' style={{color: "#fff", display: "inline-block", padding: "10px 30px"}} to='/'>Home</Link>
      <div style={{backgroundColor: "white", color: "black"}}>
        <h1><center>Your order has been cancelled.</center></h1>
      </div>
      <Link style={{ padding: "10px 30px" }} to='/'>Go Back</Link>
    </div>
  )
}

export default Cancel