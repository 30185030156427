import { Link } from 'react-router-dom'
//import '../css/footer.css'
import '../../css/style.css'

const Footer = () => {

  return (
    <footer>
      <div class="container">
        <div class="footer-cols">         
          <ul>
            <li>Oranges</li>
            <li><a href="">tangelo</a></li>
            <li><a href="">clemantine</a></li>
            <li><a href="">fremont</a></li>
            <li><a href="">tangor</a></li>
            <li><a href="">mandarins</a></li>
            <li><a href="">ocievala</a></li>
            <li><a href="">fershell</a></li>
          </ul>

          <ul>
            <li>Animals</li>
            <li><a href="">Metisse austin</a></li>
            <li><a href="">metisse montbelliard</a></li>
            <li><a href="">niveau de sang</a></li>
            <li><a href="">veaux</a></li>
            <li><a href="">genisse</a></li>
            <li><a href="">taurions</a></li>
            <li><a href="">vaches</a></li>
          </ul>

          <ul>
            <li>Pigeons</li>
            <li><a href="">mondain</a></li>
            <li><a href="">metisse mondain</a></li>
          </ul>
          <ul>
            <li>Magasinez et apprenez</li>
            <li><a href="">Oranges</a></li>
            <li><a href="">Vaches</a></li>
            <li><a href="">Pigeon</a></li>
            <li><a href="">Lait</a></li>
          </ul>
        </div>
      </div>
      <div class="footer-bottom text-center">
        Copyright &copy; 2022 AsanoCloudSolution, LLC
      </div>
    </footer>
  )
}

const headerStyle = {
  background: '#333',
  color: '#fff',
  textAlign: 'center',
  padding: '10px'
}

const linkStyle = {
  color: '#fff',
  textDecoration: 'none'
}




export default Footer
