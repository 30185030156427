import { Link } from 'react-router-dom'

const Success = () => {
  return (
    <div>
      <Link  class='cf' style={{color: "#fff", display: "inline-block", padding: "10px 30px"}} to='/'>Home</Link>
      <div style={{backgroundColor: "white", color: "black"}}>
        <h1><center>Thanks for your payment.</center></h1>
        <h1><center>Your order has been placed sucessfully.</center></h1>
      </div>
      <Link style={{ padding: "10px 30px" }} to='/'>Go Back</Link>
    </div>
  )
}

export default Success