import logo from './logo.svg';
import farm from './img/farm.jpg'
import cards from './img/cards.jpg'
//import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
// import Showcase from './components/en/Showcase'
// import Oranges from './components/en/Oranges'
// import Cows from './components/en/Cows'
// import Pigeons from './components/en/Pigeons'
// import ShoppingCart from './components/en/ShoppingCart'
// import About from './components/en/About'
// import Footer from './components/en/Footer'

import Showcase from './components/fr/Showcase'
import Oranges from './components/fr/Oranges'
import Cows from './components/fr/Cows'
import Pigeons from './components/fr/Pigeons'
import ShoppingCart from './components/fr/ShoppingCart'
import About from './components/fr/About'
import Footer from './components/fr/Footer'

import React, { useState, useEffect } from 'react';
//import $ from 'jquery'
import Contact from './components/fr/Contact';
import Success from './components/fr/Success';
import Cancel from './components/fr/Cancel';

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path='/'
          element={
              <div>
                <Showcase />
                <Oranges />
                <Cows />
                <Pigeons />
                <Contact />
              </div>
          }
        />
        <Route path='/shopping-cart' element={<ShoppingCart />} />
        <Route path='/about' element={<About />} />
        <Route path='/success' element={<Success />} />
        <Route path='/cancel' element={<Cancel />} />
      </Routes>
      <Footer />      
    </Router>
  )
}

export default App;
