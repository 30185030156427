import React from 'react'
import '../../css/style.css'

export const Pigeons = () => {
  return (
    <section id="pigeons" className="section bg-light">
    <div className="container">  
    <div className="gift-cards">
        <div>
          {/* <img src="../img/orangeTree.jpg" alt=""> */}
          <img src= { require ('../../img/pigeon3.jpg') } />
        </div>
        <div>
          <h2>Pigeons</h2>
          <h2><p className='col2'>mondain, metisse mondain</p></h2>
          <p></p>
          <img style={{"height":"80px", "width":"150px"}} src= { require ('../../img/soldout.png') } />
          <hr/>
          <a href="#" className="text-secondary">
            <i className="fas fa-chevron-right"></i>
          </a>
        </div>
    </div>
    </div>
    </section>
  )
}

export default Pigeons