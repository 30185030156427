import React from 'react'
import { Link } from 'react-router-dom'

export const ShoppingCart = () => {
  return (
    <div>
      <Link  class='cf' style={{color: "#fff", display: "inline-block", padding: "10px 30px"}} to='/'>Page d'accueil</Link>
      <h4><center>Les produits seront bientôt disponibles</center></h4>
      <Link style={{ padding: "10px 30px" }} to='/'>Retourner</Link>
    </div>
  )
}

export default ShoppingCart
