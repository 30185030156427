import { Link } from 'react-router-dom'

const About = () => {
  return (
    <div>
      <Link  class='cf' style={{color: "#fff", display: "inline-block", padding: "10px 30px"}} to='/'>Page d'accueil</Link>
      <h4 style={{ padding: "10px 30px" }}>Le champ datte de Juillet 1973. Cet elevalage a commence dans les annee 1970.</h4>
      <Link style={{ padding: "10px 30px" }} to='/'>Retourner</Link>
    </div>
  )
}

export default About