//import '../../css/style.css'
import React, { useState, useEffect } from 'react';

import $ from 'jquery'
import { Link } from 'react-router-dom'

const Showcase = () => {

  useEffect(() => {

    $(function () {
      var menu = $('nav ul');
    
      $('#openup').on('click', function(e) {
        e.preventDefault(); menu.slideToggle();
      });
      
      $(window).resize(function(){
        var w = $(this).width(); if(w > 480 && menu.is(':hidden')) {
          menu.removeAttr('style');
        }
      });
      
      $('nav li').on('click', function(e) {                
        var w = $(window).width(); if(w < 480 ) {
          menu.slideToggle(); 
        }
      });
      $('.open-menu').height($(window).height());
    })

  }, [])

  return (
    <div id="showcase">
      <header>
        <nav class='cf'>
          <ul class='cf'>
            <li><a href='#showcase'>Page d'accueil</a></li>
            <li><a href='#oranges'>Oranges</a></li>
            <li><a href='#cows'>Animaux</a></li>
            <li><a href='#pigeons'>Pigeons</a></li>
            <li><a href='#contact'>Contactez Nous</a></li>
            <li><Link to='/about'>à propos</Link></li>
          </ul>
          <a href='#showcase' id='openup'>André Ferme</a>
        </nav>
      </header>
          <div class="section-main container">
            <h1>André Ferme</h1>
            <h2>Les meilleurs produits de Bamako, Mali</h2>
            <p class="lead hide-on-small">Nous avons les meilleures oranges, vaches, pigeons</p>
          </div>
      </div>
  )
}

export default Showcase