import React, { useState } from 'react'
//import '../css/style.css'

const Contact = () => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [comments, setComments] = useState('')

    const handleClick = async (e) => {
        e.preventDefault();
        // setName(e.target.value)
        // setEmail(e.target.value)
        // setComments(e.target.value)
        console.log(`name is ${name}`)
        console.log(`email is ${email}`)
        console.log(`comments are ${comments}`)
        if (!email||!name||!comments) {
            alert('Please fill out all fields.')
            return
        }

        const res = await fetch('https://jp0loy7wf4.execute-api.us-east-1.amazonaws.com/myFunctionSimpleDynamoDB', {
            method: 'POST',
            header: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({name: `${name}`, email: `${email}`, comments: `${comments}`})
        })
        if (!res.ok) {
            throw new Error(`HTTP error: ${res.status}`)
        } else alert('Submitted successfully')
        console.log(await res)
        console.log(await res.json())
    }

    const divstyle = {
        marginBottom: '10px'
    }
    
    const labelstyle = {
        display: 'inline-block',
        width: '100px',
        textAlign: 'right'
    }

  return (
    <section id="contact" className="section bg-light">
    <div className="container">  
    <div className="gift-cards">
        <div>
          {/* <img src="../img/orangeTree.jpg" alt=""> */}
          <img src= { require ('../../img/orangeTree2.jpg') } />
        </div>
        <div style={divstyle}>
          <h2>Pour nous contactez</h2>
          <p className='col-2'>
          Vous pouvez nous joindre par email à l'adresse suivante:<b>andrefermemali@gmail.com</b> 
          </p>
          <p>Pour plus d'information n'hésitez pas à nous contacter au <b>223-76-03-5757</b></p>
          <p>Sinon, présentez vous en personne a <b>André Ferme, Manabougou, Mali</b>.
          </p>
          
          <form>
            <div>
                <label style={labelstyle}>Nom</label>
                <input type="text" value={name} onChange={(e)=>setName(e.target.value)} />
            </div>
            <div>
                <label style={labelstyle}>Email</label>
                <input type="text" value={email} onChange={(e)=>setEmail(e.target.value)} />
            </div>
            <div>
                <label style={labelstyle}>Commentaires</label>
                <textarea rows = "5" cols = "20" name = "description" value={comments} onChange={(e)=>setComments(e.target.value)}>
                </textarea>
                {/*<input type="text" value={comments} onChange={(e)=>setComments(e.target.value)} /> */}
            </div>
            <button style={{"height":"25px", "width":"200px"}} type="submit" onClick={handleClick}>Envoyer</button>
          </form>
          
          <hr/>
          <a href="#" className="text-secondary">
            <i className="fas fa-chevron-right"></i>
          </a>
        </div>
    </div>
    </div>
    </section>
  )
}

export default Contact
